<template>
  <div>
    <div :class="isMobile ? 'checkoutHeightxs' : ''">
      <h2 class="stepsTitle" v-show="isMobile" v-html="title" />
      <AllowSubstitutions
        v-show="!isViewMode && showSubstitution"
        v-if="false"
      />
      <div class="orderItemsGroup">
        <!-- New UI for Order -->
        <div class="cart-section">
          <div
            v-for="(fulFillment, index) in currentCartDetails.fulfillments"
            :key="`${fulFillment.fulfillmentMethodID}-${index}`"
          >
            <div class="cart-order-header">
              <div class="cart-order-title">
                <div class="row items-center">
                  <div class="col-auto">
                    <span class="icon">
                      <SvgIcon
                        color="primaryOnBody"
                        :icon="fulFillment.codeName"
                      />
                    </span>
                  </div>
                  <div class="col q-pl-sm"
                    ><strong>{{ fulFillment.displayName }}</strong></div
                  >
                </div>
              </div>
              <div class="cart-order-price text-right"> Total </div>
            </div>
            <div
              class="cart-order"
              v-for="(shoppingCartItem, index) in fulFillment.shoppingCartItems"
              :key="`${shoppingCartItem.orderLineItemID}-${index}`"
            >
              <div
                :class="[
                  'cart-product sd-spacing q-ml-none',
                  shoppingCartItem.linkedProducts &&
                  shoppingCartItem.linkedProducts.length > 0
                    ? 'substitution-product'
                    : substitute(
                        shoppingCartItem.orderLineItemID,
                        shoppingCartItem.allowSubstitute
                      )
                    ? 'substitution-product'
                    : '',
                ]"
              >
                <div class="cart-produt-data">
                  <div class="produt-data flex items-start">
                    <div class="product-image">
                      <box-image
                        :link="{
                          name: 'Detail',
                          params: { seoName: shoppingCartItem.seoName },
                        }"
                        :imageSrc="shoppingCartItem.image"
                        :width="isMobile ? '80px' : '86px'"
                      />
                    </div>
                    <div class="co-product-details">
                      <div class="product--title">
                        <h3 class="ellipsis">
                          <router-link
                            :to="{
                              name: 'Detail',
                              params: { seoName: shoppingCartItem.seoName },
                            }"
                          >
                            {{ shoppingCartItem.productName }}
                          </router-link>
                        </h3>
                        <template
                          v-if="
                            ['scd', 'sd'].includes(fulFillment.codeName) &&
                            shoppingCartItem?.isSubscribeForAutoShip &&
                            subscriptionDetails(shoppingCartItem)
                              .subscriptionOption == 2
                          "
                        >
                          <q-banner
                            inline-actions
                            dense
                            rounded
                            class="alert-warning alert-sm q-py-sm q-mt-sm text-subtitle2 inline-block"
                          >
                            Recurring in every
                            <strong
                              v-text="
                                getSubFrequency(
                                  subscriptionDetails(shoppingCartItem)
                                    .frequency
                                )
                              "
                            ></strong
                            >&nbsp;
                            <q-btn
                              unelevated
                              dense
                              round
                              color="amber-8"
                              size="xs"
                              class="btn-sub-info"
                              v-show="
                                shoppingCartItem.minimumRecurringOrders > 0
                              "
                              @click="
                                isMobile &&
                                  changeDialogState({
                                    dialog: 'tooltipDialog',
                                    val: true,
                                    properties: {
                                      tooltip: `Complete ${shoppingCartItem.minimumRecurringOrders} recurring order to avoid the subscription cancellation charge.`,
                                    },
                                  })
                              "
                            >
                              <SvgIcon icon="info" size=".50rem" />
                              <q-tooltip
                                content-class="bg-grey-10 text-center"
                                content-style="font-size: 12px;"
                                anchor="top middle"
                                self="bottom middle"
                                max-width="240px"
                                v-if="!isMobile"
                              >
                                Complete
                                {{ shoppingCartItem.minimumRecurringOrders }}
                                recurring order to avoid the subscription
                                cancellation charge.
                              </q-tooltip>
                            </q-btn>
                          </q-banner>
                          <!-- <q-banner
                            inline-actions
                            dense
                            rounded
                            class="alert-warning alert-sm q-py-sm q-mt-sm text-subtitle2 inline-block"
                            v-if="shoppingCartItem.minimumRecurringOrders > 0"
                          >
                            Canceling the subscription before completing
                            {{ shoppingCartItem.minimumRecurringOrders }}
                            recurring orders may result in a subscription
                            cancellation charge.
                          </q-banner> -->
                        </template>
                        <div
                          class="cart--labels q-mt-sm"
                          v-show="
                            substitute(
                              shoppingCartItem.orderLineItemID,
                              shoppingCartItem.allowSubstitute
                            )
                          "
                        >
                          <span class="allowed">
                            Substitution Allowed
                            <SvgIcon icon="info-outline" class="q-ml-sm" />
                          </span>
                        </div>
                        <div
                          class="cart--instruction text-body2 word-break-word q-mt-sm"
                          v-if="shoppingCartItem.instruction"
                        >
                          <strong class="text-grey-6">Item instruction:</strong>
                          <br />
                          {{ shoppingCartItem.instruction || '(not set)' }}
                        </div>
                        <div class="cart--quantity q-mt-sm"
                          >{{ shoppingCartItem.qty }} x
                          {{ shoppingCartItem.price | currency }}</div
                        >
                      </div>
                      <div class="price">
                        <span
                          class="original-price"
                          v-html="
                            $options.filters.currency(
                              shoppingCartItem.totalPrice
                            )
                          "
                          v-if="
                            shoppingCartItem.totalPrice !=
                            shoppingCartItem.finalPrice
                          "
                        />
                        <span
                          :class="
                            shoppingCartItem.totalPrice !=
                            shoppingCartItem.finalPrice
                              ? 'discount-price'
                              : ''
                          "
                          v-html="
                            $options.filters.currency(
                              shoppingCartItem.finalPrice
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Linked Item -->
                <div
                  class="substitution-product--block"
                  v-if="
                    shoppingCartItem.linkedProducts &&
                    shoppingCartItem.linkedProducts.length > 0
                  "
                >
                  <div
                    class="substitution-product produt-data flex items-center"
                    v-for="(
                      linkedProduct, index
                    ) in shoppingCartItem.linkedProducts"
                    :key="`${linkedProduct.orderLineItemID}${index}`"
                  >
                    <div class="product-image">
                      <q-avatar square>
                        <box-image
                          :link="
                            linkedProduct.isSellable
                              ? {
                                  name: 'Detail',
                                  params: { seoName: linkedProduct.seoName },
                                }
                              : ''
                          "
                          :imageSrc="linkedProduct.image"
                          width="40px"
                        />
                      </q-avatar>
                      <q-avatar flat>
                        <SvgIcon icon="linked" />
                      </q-avatar>
                    </div>
                    <div class="co-product-details">
                      <div class="product--title">
                        <h4>
                          <router-link
                            v-if="linkedProduct.isSellable"
                            :to="{
                              name: 'Detail',
                              params: { seoName: linkedProduct.seoName },
                            }"
                            v-html="linkedProduct.productName"
                          />
                          <template v-else>
                            {{ linkedProduct.productName }}
                          </template>
                          <q-tooltip max-width="180px" content-class="bg-dark">
                            {{ linkedProduct.productName }}
                          </q-tooltip>
                        </h4>
                      </div>
                      <div class="price">
                        <span
                          class="original-price"
                          v-html="
                            $options.filters.currency(linkedProduct.totalPrice)
                          "
                          v-if="
                            linkedProduct.totalPrice != linkedProduct.finalPrice
                          "
                        />
                        <span
                          :class="
                            linkedProduct.totalPrice != linkedProduct.finalPrice
                              ? 'discount-price'
                              : ''
                          "
                          v-html="
                            $options.filters.currency(
                              linkedProduct.finalPrice ||
                                linkedProduct.totalPrice
                            )
                          "
                        />
                      </div>
                      <div class="flex product-edit--options">
                        <div class="product-quantity">
                          <span
                            v-html="
                              `${
                                linkedProduct.qty
                              } X ${$options.filters.currency(
                                linkedProduct.price
                              )}`
                            "
                        /></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Linked Item -->
              </div>
            </div>
          </div>
        </div>
        <!-- New UI for Order -->
      </div>
    </div>
    <StepperNavigationBtn
      :loading="loading"
      v-show="!isViewMode"
      :next="updateSubstitution"
      :label="stepperNavigationBtnLabel"
      :isReviewOrder="isReviewOrder"
      :cancelAction="cancelEdit"
    />
    <TooltipDialog v-if="isMobile" />
  </div>
</template>
<script>
import StepperNavigationBtn from './StepperNavigationBtn'
import AllowSubstitutions from './AllowSubstitutions'
import { mapGetters, mapActions } from 'vuex'
import TooltipDialog from 'components/common/label-tooltip.vue'

export default {
  name: 'OrderDetails',
  components: { StepperNavigationBtn, AllowSubstitutions, TooltipDialog },
  props: {
    next: {
      type: Function,
      default: () => {},
    },
    currentCartDetails: {
      type: Object,
      default: null,
    },
    isReviewOrder: {
      type: Boolean,
      default: false,
    },
    fulFillmentCodeName: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Order Detail',
    },
  },
  data() {
    return {
      loading: false,
      isViewMode: this.isReviewOrder,
    }
  },
  computed: {
    stepperNavigationBtnLabel() {
      return this.isReviewOrder ? 'Apply' : ''
    },
    ...mapGetters('cart', [
      'substituteDetails',
      'recentSubstituteDetail',
      'substitute',
      'isSubstitutionAllowed',
      'showSubstitution',
      'currentCartNumber',
    ]),
    ...mapGetters('persistedLocal', ['getCartPersistedInfo']),
    cartPersistedInfo() {
      return this.getCartPersistedInfo(this.currentCartNumber)
    },
  },
  methods: {
    ...mapActions('cart', ['toggleSubstitution', 'toggleSubstitute']),
    async updateSubstitution() {
      let params = Object.values(this.recentSubstituteDetail)

      if (params.length) {
        this.loading = true

        this.$store
          .dispatch('cart/updateSubstitution', params, {
            resetSubstitution: false,
          })
          .then((response) => {
            this.loading = false
            if (this.isReviewOrder) {
              this.isViewMode = true
            } else {
              this.next()
            }
          })
      } else {
        if (this.isReviewOrder) {
          this.isViewMode = true
        } else {
          this.next()
        }
      }
    },
    cancelEdit() {
      this.$store.commit('cart/RESET_RECENT_SUBSTITUTE')
      this.isViewMode = true
    },
    subscriptionDetails(shoppingCartItem) {
      let obj = {
        subscriptionOption: 1,
        frequency: null,
      }
      if (!shoppingCartItem) return obj
      let cartItemSubscriptionDetails =
        this.cartPersistedInfo?.subscriptionDetails

      if (
        ['scd', 'sd'].includes(this.selectedFulfillmentCodeName) &&
        shoppingCartItem?.isinstock &&
        shoppingCartItem?.isSubscribeForAutoShip &&
        cartItemSubscriptionDetails &&
        cartItemSubscriptionDetails[shoppingCartItem.orderLineItemID]
      ) {
        let subscriptionDetails =
          cartItemSubscriptionDetails[shoppingCartItem.orderLineItemID]
        if (
          subscriptionDetails?.subscriptionOption == 2 &&
          shoppingCartItem?.frequencies.length
        ) {
          let frequencyData = shoppingCartItem?.frequencies.find(
            (item) => item.intervalDays == subscriptionDetails.frequency
          )
          obj = {
            subscriptionOption: subscriptionDetails?.subscriptionOption,
            frequency: frequencyData.intervalDays || 0,
          }
        }
      }
      return obj
    },
    getSubFrequency(frequency) {
      return `${frequency} ${frequency > 1 ? 'Days' : 'Day'}`
    },
  },
  destroyed() {
    this.$store.commit('cart/RESET_RECENT_SUBSTITUTE')
  },
  watch: {
    isReviewOrder: function (newVal, oldVal) {
      if (newVal != oldVal) this.isViewMode = !!newVal
    },
  },
}
</script>

<style lang="scss">
.orderItems {
  .link-products {
    .linked-product {
      .order-review-qty {
        @media (min-width: 991px) {
          max-width: 120px;
          // padding-left: 15px;
        }
      }
      .OrdetItemTotal {
        min-width: 72px !important;
      }
      .cartProductSide {
        width: 80px;
        text-align: right;
      }
      .q-item {
        padding-right: 0;
      }
    }
  }
}
</style>
