import { render, staticRenderFns } from "./OrderDetails.vue?vue&type=template&id=f9b8f860"
import script from "./OrderDetails.vue?vue&type=script&lang=js"
export * from "./OrderDetails.vue?vue&type=script&lang=js"
import style0 from "./OrderDetails.vue?vue&type=style&index=0&id=f9b8f860&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBanner,QBtn,QTooltip,QAvatar,QItem});
